
import Vue from "@/interfaces/vue";
import { Component } from "vue-property-decorator";
import Calendar from "@/components/Calendar.vue";
import vSelect from "vue-select";
import Booking from "@/components/Booking.vue";

import { Review } from "@/interfaces/therapy";
import { AppliedFilters } from "@/interfaces/misc";
import { Therapist } from "@/interfaces/therapist";
import { TherapistDates } from "@/interfaces/visit";
import { Fragment } from "vue-fragment";
import { DataDownloader, get } from "@/api";

@Component({
  components: {
    Calendar,
    vSelect,
    Fragment,
    Booking,
  },
  metaInfo: {
    title: "Therapist Profile",
  },
})
export default class TherapistProfile extends Vue {
  therapists = new DataDownloader<Therapist[]>(get.therapists);

  reviews = new DataDownloader<Review[]>(() =>
    get.therapistReviews((this.$route.params.id as unknown) as number)
  );

  therapistDates = new DataDownloader<TherapistDates>(() =>
    get.therapistDates((this.$route.params.id as unknown) as number)
  );

  pickedDate = new Date();
  baseUrl = process.env.VUE_APP_TARGET;

  appliedFilters: AppliedFilters = {
    meetingType: "first-visit",
    visitType: "has_online_dates",
    specializations: [],
  };

  get therapist() {
    return this.therapists.data?.find(
      (therapist) => therapist.id.toString() === this.$route.params.id
    );
  }

  get visitTypes() {
    if (this.$store.state.dict) {
      const visitTypes = this.$store.state.dict.visit_types;

      return this.therapistDates.data?.visit_types.map((visit) => ({
        ...visit,
        visit_type: visitTypes.find((type) => type.id === visit.visit_type)
          ?.name,
      }));
    } else return [];
  }
}
