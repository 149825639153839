var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"main"},[(
      _vm.therapists.loaded &&
        _vm.therapistDates.loaded &&
        _vm.reviews.loaded &&
        _vm.therapist
    )?_c('fragment',[_c('div',[_c('div',{staticClass:"therapist-info"},[_c('div',{staticClass:"general-info"},[_c('img',{attrs:{"src":_vm.baseUrl + _vm.therapist.photo}}),_c('div',{staticClass:"info-text"},[_c('h2',[_vm._v(" "+_vm._s(_vm.therapist.user.first_name)+" "+_vm._s(_vm.therapist.user.last_name)+" ")]),_c('h3',[_vm._v(" "+_vm._s(_vm.$t(("therapist.list." + (_vm.therapist.specializations[0]))))+" ")])])])]),(_vm.reviews.data.length !== 0 && _vm.reviews.data[0].text)?_c('div',{staticClass:"review card"},[_c('div',[_vm._v(" "+_vm._s(_vm.reviews.data[0].text)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.reviews.data[0].name)+" ")]),_c('i',{staticClass:"fas fa-quote-right"}),_c('i',{staticClass:"fas fa-quote-right"})]):_vm._e(),_c('div',{staticClass:"more-info"},[(_vm.therapist.description)?_c('div',[_c('h2',{attrs:{"id":"about-title"}},[_vm._v(_vm._s(_vm.$t("therapist.view.about-me")))]),_c('div',[_vm._v(" "+_vm._s(_vm.therapist.description)+" ")])]):_vm._e(),_c('div',[_c('h2',{attrs:{"id":"specs-title"}},[_vm._v(_vm._s(_vm.$t("therapist.view.specialities")))]),_vm._l((_vm.therapist.specializations),function(specialization){return _c('div',{key:specialization},[_vm._v(" "+_vm._s(_vm.$t("information-filling.specializations." + specialization))+" ")])})],2)]),_c('div',{staticClass:"experiance"},[_c('h2',[_vm._v(_vm._s(_vm.$t("therapist.view.background")))]),(_vm.therapist.languages.length > 0)?_c('div',[_c('h3',[_vm._v(_vm._s(_vm.$t("therapist.view.languages")))]),_vm._l((_vm.therapist.languages),function(language){return _c('h5',{key:language},[_vm._v(" "+_vm._s(_vm.$t("languages." + language))+" ")])})],2):_vm._e()]),_c('div',{staticClass:"opinions"}),_c('div')]),_c('div',[_c('booking',{attrs:{"props":{
          therapist: this.therapist,
          pickedDate: _vm.pickedDate,
          therapistDates: this.therapistDates.data,
          visitTypes: _vm.visitTypes,
          visitName: this.appliedFilters.visitType,
          meetingName: this.appliedFilters.meetingType,
        },"normalView":true}})],1)]):_c('div',[_c('spinner')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }